export const IconArrowMergeAltLeft = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M8 7l4 -4l4 4" />
  <path d="M18 21v.01" />
  <path d="M18 18.01v.01" />
  <path d="M17 15.02v.01" />
  <path d="M14 13.03v.01" />
  <path d="M12 3v5.394a6.737 6.737 0 0 1 -3 5.606a6.737 6.737 0 0 0 -3 5.606v1.394" />
</svg>


`