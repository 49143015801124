export const IconFileTypeVue = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
  <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
  <path d="M4 15l2 6l2 -6" />
  <path d="M11 15v4.5a1.5 1.5 0 0 0 3 0v-4.5" />
  <path d="M20 15h-3v6h3" />
  <path d="M17 18h2" />
</svg>


`