export const IconDeviceProjector = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M8 9a5 5 0 1 0 10 0a5 5 0 0 0 -10 0" />
  <path d="M9 6h-4a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h14a2 2 0 0 0 2 -2v-8a2 2 0 0 0 -2 -2h-2" />
  <path d="M6 15h1" />
  <path d="M7 18l-1 2" />
  <path d="M18 18l1 2" />
</svg>


`