export const IconSwitch = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M15 4l4 0l0 4" />
  <path d="M14.75 9.25l4.25 -5.25" />
  <path d="M5 19l4 -4" />
  <path d="M15 19l4 0l0 -4" />
  <path d="M5 5l14 14" />
</svg>


`