export const IconLifebuoyOff = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M9.171 9.172a4 4 0 0 0 5.65 5.663m1.179 -2.835a4 4 0 0 0 -4 -4" />
  <path d="M5.64 5.632a9 9 0 1 0 12.73 12.725m1.667 -2.301a9 9 0 0 0 -12.077 -12.1" />
  <path d="M15 15l3.35 3.35" />
  <path d="M9 15l-3.35 3.35" />
  <path d="M5.65 5.65l3.35 3.35" />
  <path d="M18.35 5.65l-3.35 3.35" />
  <path d="M3 3l18 18" />
</svg>


`