export const IconBrandLinktree = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M4 10h16" />
  <path d="M6.5 4.5l11 11" />
  <path d="M6.5 15.5l11 -11" />
  <path d="M12 10v-8" />
  <path d="M12 15v7" />
</svg>


`