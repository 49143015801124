export const IconXxx = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M10 8l4 8" />
  <path d="M10 16l4 -8" />
  <path d="M17 8l4 8" />
  <path d="M17 16l4 -8" />
  <path d="M3 8l4 8" />
  <path d="M3 16l4 -8" />
</svg>


`