export const IconMapExclamation = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M15 20l-6 -3l-6 3v-13l6 -3l6 3l6 -3v8.5" />
  <path d="M9 4v13" />
  <path d="M15 7v13" />
  <path d="M19 16v3" />
  <path d="M19 22v.01" />
</svg>


`