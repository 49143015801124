export const IconMoodNerd = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
  <path d="M8 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
  <path d="M16 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
  <path d="M9.5 15a3.5 3.5 0 0 0 5 0" />
  <path d="M3.5 9h2.5" />
  <path d="M18 9h2.5" />
  <path d="M10 9.5c1.333 -1.333 2.667 -1.333 4 0" />
</svg>


`