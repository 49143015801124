export const IconFileTypeTxt = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
  <path d="M16.5 15h3" />
  <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
  <path d="M4.5 15h3" />
  <path d="M6 15v6" />
  <path d="M18 15v6" />
  <path d="M10 15l4 6" />
  <path d="M10 21l4 -6" />
</svg>


`