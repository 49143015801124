export const IconSpray = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M4 10m0 2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v7a2 2 0 0 1 -2 2h-4a2 2 0 0 1 -2 -2z" />
  <path d="M6 10v-4a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v4" />
  <path d="M15 7h.01" />
  <path d="M18 9h.01" />
  <path d="M18 5h.01" />
  <path d="M21 3h.01" />
  <path d="M21 7h.01" />
  <path d="M21 11h.01" />
  <path d="M10 7h1" />
</svg>


`