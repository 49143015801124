export const IconDeviceDesktopExclamation = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M15 16h-11a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v7" />
  <path d="M7 20h8" />
  <path d="M9 16v4" />
  <path d="M15 16v4" />
  <path d="M19 16v3" />
  <path d="M19 22v.01" />
</svg>


`