export const IconGenderBigender = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M11 11m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
  <path d="M19 3l-5 5" />
  <path d="M15 3h4v4" />
  <path d="M11 16v6" />
  <path d="M8 19h6" />
</svg>


`