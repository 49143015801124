export const IconListLetters = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M11 6h9" />
  <path d="M11 12h9" />
  <path d="M11 18h9" />
  <path d="M4 10v-4.5a1.5 1.5 0 0 1 3 0v4.5" />
  <path d="M4 8h3" />
  <path d="M4 20h1.5a1.5 1.5 0 0 0 0 -3h-1.5h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6z" />
</svg>


`