export const IconGrid4x4 = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M3 6h18" />
  <path d="M3 12h18" />
  <path d="M3 18h18" />
  <path d="M6 3v18" />
  <path d="M12 3v18" />
  <path d="M18 3v18" />
</svg>


`