export const IconWheelchair = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M8 16m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0" />
  <path d="M19 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
  <path d="M19 17a3 3 0 0 0 -3 -3h-3.4" />
  <path d="M3 3h1a2 2 0 0 1 2 2v6" />
  <path d="M6 8h11" />
  <path d="M15 8v6" />
</svg>


`