export const IconRewindBackward40 = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M19.007 16.466a6 6 0 0 0 -4.007 -10.466h-11" />
  <path d="M12 15.5v3a1.5 1.5 0 0 0 3 0v-3a1.5 1.5 0 0 0 -3 0z" />
  <path d="M6 14v2a1 1 0 0 0 1 1h1" />
  <path d="M9 14v6" />
  <path d="M7 9l-3 -3l3 -3" />
</svg>


`