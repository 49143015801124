export const IconAntenna = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M20 4v8" />
  <path d="M16 4.5v7" />
  <path d="M12 5v16" />
  <path d="M8 5.5v5" />
  <path d="M4 6v4" />
  <path d="M20 8h-16" />
</svg>


`