export const IconBrandGooglePodcasts = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M12 3v2" />
  <path d="M12 19v2" />
  <path d="M12 8v8" />
  <path d="M8 17v2" />
  <path d="M4 11v2" />
  <path d="M20 11v2" />
  <path d="M8 5v8" />
  <path d="M16 7v-2" />
  <path d="M16 19v-8" />
</svg>


`