export const IconSignalLte = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M21 8h-4v8h4" />
  <path d="M17 12h2.5" />
  <path d="M4 8v8h4" />
  <path d="M10 8h4" />
  <path d="M12 8v8" />
</svg>


`