export const IconWorldPause = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M20.945 12.997a9 9 0 1 0 -7.928 7.945" />
  <path d="M3.6 9h16.8" />
  <path d="M3.6 15h9.9" />
  <path d="M11.5 3a17 17 0 0 0 0 18" />
  <path d="M12.5 3a16.992 16.992 0 0 1 2.51 10.526" />
  <path d="M17 17v5" />
  <path d="M21 17v5" />
</svg>


`