export const IconSortAscending = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M4 6l7 0" />
  <path d="M4 12l7 0" />
  <path d="M4 18l9 0" />
  <path d="M15 9l3 -3l3 3" />
  <path d="M18 6l0 12" />
</svg>


`