export const IconWheelchairOff = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M8 16m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0" />
  <path d="M17.582 17.59a2 2 0 0 0 2.833 2.824" />
  <path d="M14 14h-1.4" />
  <path d="M6 6v5" />
  <path d="M6 8h2m4 0h5" />
  <path d="M15 8v3" />
  <path d="M3 3l18 18" />
</svg>


`