export const IconDice6 = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
  <circle cx="8.5" cy="7.5" r=".5" fill="currentColor" />
  <circle cx="15.5" cy="7.5" r=".5" fill="currentColor" />
  <circle cx="8.5" cy="12" r=".5" fill="currentColor" />
  <circle cx="15.5" cy="12" r=".5" fill="currentColor" />
  <circle cx="15.5" cy="16.5" r=".5" fill="currentColor" />
  <circle cx="8.5" cy="16.5" r=".5" fill="currentColor" />
</svg>


`