export const IconDeviceWatchDown = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M12 18h-3a3 3 0 0 1 -3 -3v-6a3 3 0 0 1 3 -3h6a3 3 0 0 1 3 3v3" />
  <path d="M9 18v3h3.5" />
  <path d="M9 6v-3h6v3" />
  <path d="M19 16v6" />
  <path d="M22 19l-3 3l-3 -3" />
</svg>


`