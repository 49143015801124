export const IconFileTypePhp = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
  <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
  <path d="M5 18h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6" />
  <path d="M17 18h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6" />
  <path d="M11 21v-6" />
  <path d="M14 15v6" />
  <path d="M11 18h3" />
</svg>


`