export const IconDeviceImacX = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M13 17h-9a1 1 0 0 1 -1 -1v-12a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v9" />
  <path d="M3 13h18" />
  <path d="M8 21h5" />
  <path d="M10 17l-.5 4" />
  <path d="M22 22l-5 -5" />
  <path d="M17 22l5 -5" />
</svg>


`