export const IconDeviceRemote = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M12 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
  <path d="M7 3m0 2a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2z" />
  <path d="M12 3v2" />
  <path d="M10 15v.01" />
  <path d="M10 18v.01" />
  <path d="M14 18v.01" />
  <path d="M14 15v.01" />
</svg>


`