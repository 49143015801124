export const IconHierarchy2 = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M10 3h4v4h-4z" />
  <path d="M3 17h4v4h-4z" />
  <path d="M17 17h4v4h-4z" />
  <path d="M7 17l5 -4l5 4" />
  <path d="M12 7l0 6" />
</svg>


`