export const IconBrandBilibili = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M3 10a4 4 0 0 1 4 -4h10a4 4 0 0 1 4 4v6a4 4 0 0 1 -4 4h-10a4 4 0 0 1 -4 -4v-6z" />
  <path d="M8 3l2 3" />
  <path d="M16 3l-2 3" />
  <path d="M9 13v-2" />
  <path d="M15 11v2" />
</svg>


`