export const IconCircleHalf2 = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
  <path d="M12 3v18" />
  <path d="M12 14l7 -7" />
  <path d="M12 19l8.5 -8.5" />
  <path d="M12 9l4.5 -4.5" />
</svg>


`