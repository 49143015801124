export const IconTallymarks = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M6 5l0 14" />
  <path d="M10 5l0 14" />
  <path d="M14 5l0 14" />
  <path d="M18 5l0 14" />
  <path d="M3 17l18 -10" />
</svg>


`