export const IconChartTreemap = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
  <path d="M12 4v16" />
  <path d="M4 15h8" />
  <path d="M12 12h8" />
  <path d="M16 12v8" />
  <path d="M16 16h4" />
</svg>


`