export const IconAirTrafficControl = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M11 3h2" />
  <path d="M12 3v3" />
  <path d="M5.998 6h12.004a2 2 0 0 1 1.916 2.575l-1.8 6a2 2 0 0 1 -1.916 1.425h-8.404a2 2 0 0 1 -1.916 -1.425l-1.8 -6a2 2 0 0 1 1.916 -2.575z" />
  <path d="M8.5 6l1.5 10v5" />
  <path d="M15.5 6l-1.5 10v5" />
</svg>


`